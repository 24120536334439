const qs = require("qs");

const { BUSINESS_MODELS } = require("../constants");
const { getLanguageCode } = require("../v2/lib/getLanguageCode");

const {
  getSolJourneyConfigForJourney,
} = require("../v2/lib/getSolJourneyConfigForJourney");

const {
  getIsCashJourneySelected,
} = require("../v2/lib/getIsCashJourneySelected");

const {
  getIsLeasingJourneySelected,
} = require("../v2/lib/getIsLeasingJourneySelected");

const sortById = (a, b) => {
  if (a.id > b.id) {
    return 1;
  }

  if (b.id > a.id) {
    return -1;
  }

  return 0;
};

const generateConfigurationUrl = ({
  appConfiguration,
  selectedLanguage,
  selectedPreconfiguration,
  selectedAccessories,
  selectedServices,
  selectedJourney,
  selectedSalesman,
  selectedBusinessModel,
  action = null,
  financeWidgetParameters,
  isScrappageSelected = false,
}) => {
  const hasAccessories = selectedAccessories && selectedAccessories.length > 0;
  const hasServices = selectedServices && selectedServices.length > 0;

  let url = "/";

  if (appConfiguration.localisation.allowedLanguages.length > 1) {
    url += getLanguageCode(selectedLanguage) + "/";
  }

  if (selectedBusinessModel === BUSINESS_MODELS.B2B) {
    url += appConfiguration.b2b.urlPrefix + "/";
  }

  url += selectedPreconfiguration.slug;

  const isLeasingJourneySelected = getIsLeasingJourneySelected(selectedJourney);
  const isCashJourney = getIsCashJourneySelected(selectedJourney);

  if (hasAccessories) {
    url += `/${[...selectedAccessories]
      .sort(sortById)
      .map(accessory => accessory.slug)
      .join("/")}`;
  }

  if (hasServices && isCashJourney) {
    url += `/${selectedServices
      .sort(sortById)
      .map(service => service.slug)
      .join("/")}`;
  }

  const queryParams = {
    journey: selectedJourney,
    salesman: selectedSalesman,
    action,
  };

  if (selectedJourney) {
    const {
      scrappage: { enabled: isScrappageEnabled },
    } = getSolJourneyConfigForJourney(
      selectedJourney,
      selectedBusinessModel,
      appConfiguration
    );

    if (isScrappageEnabled) {
      queryParams.isScrappageSelected = isScrappageSelected;
    }
  }

  url += qs.stringify(queryParams, { addQueryPrefix: true, skipNulls: true });

  if (financeWidgetParameters && isLeasingJourneySelected) {
    const hashFinanceWidgetParameters = window.btoa(
      JSON.stringify(financeWidgetParameters)
    );

    url += `&${qs.stringify({ FWState: hashFinanceWidgetParameters })}`;
  }

  return url;
};

module.exports = { generateConfigurationUrl };
