import React from "react";

import BaseIcon from "../BaseIcon";

const ShareIcon = props => (
  <BaseIcon
    version="1.1"
    viewBox="0 0 20.671 20.668"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m16.334 4.2691e-7c-2.8642 0.004371-4.8459 2.751-4.1172 5.4609l-4.4883 2.2441c-1.1945-1.554-3.0587-2.0055-4.6543-1.5215-1.6813 0.51-3.0769 2.0269-3.0742 4.1504 0 2.3867 1.9473 4.334 4.334 4.334 1.3389-5.8e-4 2.5523-0.67074 3.3691-1.7168l4.5137 2.2559c-0.72954 2.7103 1.2524 5.4568 4.1172 5.4609 2.3867 0 4.334-1.9473 4.334-4.334s-1.9473-4.334-4.334-4.334c-1.3389 5.8e-4 -2.5523 0.67074-3.3691 1.7168l-4.4922-2.2441c0.10155-0.36717 0.19531-0.73983 0.19531-1.1387 0-0.39884-0.093762-0.7715-0.19531-1.1387l4.498-2.248c1.8935 2.4207 5.7048 2.2038 7.1875-0.57031 1.5362-2.8742-0.56521-6.3784-3.8242-6.377zm0 1.668c1.4732 0 2.666 1.1929 2.666 2.666s-1.1929 2.666-2.666 2.666-2.666-1.1929-2.666-2.666 1.1929-2.666 2.666-2.666zm-12 6c1.4732 0 2.666 1.1929 2.666 2.666 0 1.4732-1.1929 2.666-2.666 2.666s-2.666-1.1929-2.666-2.666c0-1.4732 1.1929-2.666 2.666-2.666zm12 6c1.4732 0 2.666 1.1929 2.666 2.666s-1.1929 2.666-2.666 2.666-2.666-1.1929-2.666-2.666 1.1929-2.666 2.666-2.666z"
      fill="currentColor"
    />
  </BaseIcon>
);

export default ShareIcon;
