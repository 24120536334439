const getConfig = require("next/config").default;
const { pathToRegexp, compile, match } = require("path-to-regexp");
const qs = require("qs");

const { LOCALES } = require("../constants");
const { getLanguageCode } = require("../v2/lib/getLanguageCode");

const {
  LOCALE_IDENTIFIER,
  ROUTES_TO_CONFIGURATION_KEYS_MAP,
} = require("./routes");

const EMPTY_STRING = "";

class RoutingUtils {
  constructor(config) {
    this.config = config;
    this.config.remoteTranslationsEnabled =
      getConfig()?.publicRuntimeConfig?.I18NEXT_OVER_HTTP_ENABLED || false;
  }

  get AVAILABLE_ROUTES() {
    const { remoteTranslationsEnabled, routes } = this.config;

    const routesHashMap = routes.reduce((acc, route) => {
      const routesForGivenLanguage = acc[route.language] ?? {
        [LOCALE_IDENTIFIER]: route.language,
      };

      const routeAppKey = Object.entries(ROUTES_TO_CONFIGURATION_KEYS_MAP).find(
        ([, configurationKey]) => configurationKey === route.id
      )?.[0];

      if (!routeAppKey) {
        return acc;
      }

      return {
        ...acc,
        [route.language]: {
          ...routesForGivenLanguage,
          [routeAppKey]: route.translation,
        },
      };
    }, {});

    const result = Object.values(routesHashMap);

    if (remoteTranslationsEnabled) {
      result.forEach(routesSet => {
        const id = routesSet[LOCALE_IDENTIFIER];

        if (id.length > 2) {
          result.push({ ...routesSet, [LOCALE_IDENTIFIER]: id.substr(0, 2) });
        }
      });
    }

    return result;
  }

  combineRoutes(routeObjects) {
    return routeObjects
      .map(routeObject =>
        Object.fromEntries(
          Object.entries(routeObject).map(([id, url]) => [url, id])
        )
      )
      .reduce(
        (acc, cur) => ({
          ...cur,
          ...acc,
        }),
        {}
      );
  }

  getLocaleFromPathname(pathname, allowedLanguages = []) {
    const languageInUrl = allowedLanguages.find(allowedLanguage =>
      pathname.startsWith(`/${getLanguageCode(allowedLanguage)}/`)
    );

    if (languageInUrl) {
      return languageInUrl;
    }

    const targetRouteObject = this.AVAILABLE_ROUTES.find(routeObject =>
      Object.values(routeObject)
        .map(path => pathToRegexp(path))
        .some(regex => regex.test(pathname))
    );

    return targetRouteObject?.[LOCALE_IDENTIFIER];
  }

  getRouteFromPathname(pathname) {
    const combinedRoutes = this.combineRoutes(this.AVAILABLE_ROUTES);
    const targetObjectEntry = Object.entries(combinedRoutes).find(([path]) =>
      pathToRegexp(path).test(pathname)
    );

    return targetObjectEntry ? targetObjectEntry[1] || null : null;
  }

  getPathForPathname(pathname) {
    const combinedRoutes = this.combineRoutes(this.AVAILABLE_ROUTES);
    const targetObjectEntry = Object.entries(combinedRoutes).find(([path]) =>
      pathToRegexp(path).test(pathname)
    );

    return targetObjectEntry ? targetObjectEntry[0] || null : null;
  }

  getParamsFromPathname(pathname) {
    const targetPath = this.getPathForPathname(pathname);
    const matcher = match(targetPath, { decode: decodeURIComponent });
    const { params } = matcher(pathname);

    return { ...params };
  }

  getLocalizedPathname(route, params, locale, queryParams) {
    if (!Object.values(LOCALES).includes(locale)) {
      throw new Error(`Invalid locale '${locale}'`);
    }

    const pathsForLocale = this.AVAILABLE_ROUTES.find(
      routeObject => routeObject[LOCALE_IDENTIFIER] === locale
    );

    const targetPath = route ? pathsForLocale[route] : EMPTY_STRING;

    let compiledPath = targetPath;

    if (params) {
      const pathnameCompiler = compile(targetPath, {
        encode: encodeURIComponent,
      });

      compiledPath = pathnameCompiler(params);
    }

    if (queryParams && Object.keys(queryParams).length > 0) {
      const queryString = new URLSearchParams(queryParams).toString();
      return `${compiledPath}?${queryString}`;
    }

    return compiledPath;
  }

  buildPathname(pathname, query) {
    const queryOptions = {
      addQueryPrefix: true,
      skipNulls: true,
    };

    return `${pathname}${qs.stringify(query, queryOptions)}`;
  }
}

module.exports = RoutingUtils;
